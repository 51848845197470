<template>
  <div>
    <Slider />
    <Description section="smes" />
    <Strengths :strengths="strengths" title="Key Services" />
    <Slogan />
    <TestimonialsPage />
    <Banner />
  </div>
</template>

<script>
export default {
    name: "SMEs",
       components: {
       Slider: () => import('./sections/sliders/SMEs'),
       Description: () => import('./sections/description'),
       Strengths: () => import('./sections/strengths'),
       Slogan: () => import('./sections/slogan'),
       TestimonialsPage: () => import('../home/testimonials'),
       Banner: () => import('./sections/banner'),

    },
    computed: {
       strengths () {
         const data = [
                {
                    image: ['mpesa.png','vodacom.jpeg',  'mtn.png', 'tigo.png'],
                    title: `Receive Payments  `,
                    text: 'Integrate Zamupay and let your customers pay you however they want.',
                    color: '#ffffff',
                },
                 {
                    image: ['mpesa.png','vodacom.jpeg',  'mtn.png', 'tigo.png'],
                    title: 'Make Payments',
                    text: 'Zamupay helps you pay your vendors & suppliers through mobile money networks like Mpesa, MTN, Vodacom, Tigo etc.',
                    color: '#ffffff',
                },
                {
                    image: ['telcom.jpeg','dstv.jpeg','kplc.jpeg',  'water.jpeg'],
                    title: 'Utility Bills ',
                    text: 'Pay for your utility bills such as Electricity, Water, TV Subscription and Internet',
                    color: '#ffffff',
                },
             
               
                {
                    image: ['nhif.jpeg','nssf.jpeg','kra.jpeg', 'helb.jpeg'],
                    title: 'Statutory Payments',
                    text: 'Zamupay easily allows your business to make payments required to be made to Government Authorities in terms of Applicable Law. These may include: KRA, NHIF and NSSF.',
                    color: '#ffffff',
                },
         ];
         return data;
       }

    },

}
</script>

<style>

</style>